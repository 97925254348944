import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

const Home = lazy(() => import("./components/pages/Home"));
const ChatBot = lazy(() => import("./components/ChatBot/ChatBot"));

function App() {
  const [hasVisitedHome, setHasVisitedHome] = useState(
    JSON.parse(localStorage.getItem("hasVisitedHome")) || false
  );

  useEffect(() => {
    localStorage.setItem("hasVisitedHome", JSON.stringify(hasVisitedHome));
  }, [hasVisitedHome]);

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={<Home setHasVisitedHome={setHasVisitedHome} />}
          />
          <Route
            path="/chat"
            element={hasVisitedHome ? <ChatBot /> : <Navigate to="/" replace />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
